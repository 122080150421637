import { useDispatch, useSelector } from "react-redux";
import { Button, Menu, Modal } from "antd";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { setmodule, setselectedKey } from "../slices/mapView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faBook,
  faCircleExclamation,
  faGauge,
  faHouseChimney,
  faSunPlantWilt,
  faUserGear,
} from "@fortawesome/free-solid-svg-icons";

const config = {
  title: "Disclaimer",
  content: (
    <div>
      <p className="warning-text">
        Disclaimer: IWMI has been following the guidelines of the UN on
        territorial boundaries for publications. The guidelines can be accessed
        &nbsp;
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.ifpri.org/sites/default/files/guidelines_countries-territories-maps.pdf"
        >
          here
        </a>
      </p>
    </div>
  ),
};

function SubMenu() {
  const { darkmode, admin, loggedin, selectedKey } = useSelector(
    (state) => state
  );
  const [color, setcolor] = useState("#000");
  const [modal, contextHolder] = Modal.useModal();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setcolor(darkmode ? "#fff" : "#000");
  }, [darkmode]);

  return (
    <>
      <Menu
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
        }}
        mode="horizontal"
        selectedKeys={selectedKey}
        items={[
          {
            label: (
              <Button
                type="link"
                style={{ textDecoration: "none" }}
                href="/home"
                icon={<FontAwesomeIcon icon={faHouseChimney} />}
                onClick={() => dispatch(setselectedKey("home"))}
              >
                Home
              </Button>
            ),
            key: "home",
          },
          {
            label: (
              <Button
                type="link"
                style={{ textDecoration: "none" }}
                href="/aboutus"
                icon={<FontAwesomeIcon icon={faAddressCard} />}
                onClick={() => dispatch(setselectedKey("about"))}
              >
                About Us
              </Button>
            ),
            key: "about",
          },
          {
            label: (
              <Button
                type="link"
                style={{ textDecoration: "none" }}
                href="/map"
                onClick={() => {
                  navigate("");
                  dispatch(setmodule("monitoring"));
                  dispatch(setselectedKey("monitoring"));
                }}
                icon={<FontAwesomeIcon icon={faSunPlantWilt} />}
              >
                Drought Monitoring
              </Button>
            ),
            key: "monitoring",
          },
          // {
          //   label: (
          //     <Button
          //       type="link"
          //       href="/map"
          //       style={{ textDecoration: "none" }}
          //       onClick={() => {
          //         dispatch(setmodule("prediction"));
          //         dispatch(setselectedKey("prediction"));
          //       }}
          //       icon={<FontAwesomeIcon icon={faMagnifyingGlassChart} />}
          //     >
          //       Drought Prediction
          //     </Button>
          //   ),
          //   key: "prediction",
          // },
          {
            label: (
              <Button
                href="#"
                type="link"
                onClick={async () => {
                  modal.info(config);
                  dispatch(setselectedKey(null));
                }}
                icon={<FontAwesomeIcon icon={faCircleExclamation} />}
              >
                Disclaimer
              </Button>
            ),
            key: "disclaimer",
          },
          {
            ...(!loggedin
              ? {
                  label: (
                    <Button
                      href="/register"
                      style={{ textDecoration: "none" }}
                      type="link"
                      icon={<FontAwesomeIcon icon={faUserGear} />}
                    >
                      Register Now
                    </Button>
                  ),
                  key: "register",
                }
              : ""),
          },
          {
            label: (
              <Button
                href="/aboutus"
                style={{ textDecoration: "none" }}
                type="link"
                icon={<FontAwesomeIcon icon={faBook} />}
              >
                User Manual
              </Button>
            ),
            key: "usermanual",
          },
          {
            ...(loggedin && (admin === "1" || admin === 1)
              ? {
                  label: (
                    <Button
                      style={{ textDecoration: "none" }}
                      href="/dashboard"
                      type="link"
                      icon={<FontAwesomeIcon icon={faGauge} />}
                      onClick={() => dispatch(setselectedKey("dashboard"))}
                    >
                      Admin Panel
                    </Button>
                  ),
                  key: "dashboard",
                }
              : ""),
          },
        ]}
      ></Menu>
      {/* <nav>
        <ul className="mx-auto mobi-scroll navbar-nav">
          <ul className="nav-menu-items mobi-scroll">
            <li className="">
              <a className="" href="home">
                <div className="api-block">
                  <span className="icon-color">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.50003 9.83334H2.33336V15.6667C2.33336 16.5858 3.08086 17.3333 4.00003 17.3333H14C14.9192 17.3333 15.6667 16.5858 15.6667 15.6667V9.83334H16.5C16.6648 9.8333 16.8259 9.78441 16.9629 9.69284C17.0999 9.60127 17.2067 9.47113 17.2698 9.31888C17.3328 9.16663 17.3493 8.9991 17.3172 8.83748C17.285 8.67585 17.2057 8.52738 17.0892 8.41084L9.5892 0.910838C9.51187 0.833383 9.42004 0.771935 9.31894 0.73001C9.21784 0.688084 9.10947 0.666504 9.00003 0.666504C8.89058 0.666504 8.78221 0.688084 8.68112 0.73001C8.58002 0.771935 8.48818 0.833383 8.41086 0.910838L0.910859 8.41084C0.794351 8.52738 0.715013 8.67585 0.682873 8.83748C0.650733 8.9991 0.667236 9.16663 0.730294 9.31888C0.793353 9.47113 0.900136 9.60127 1.03714 9.69284C1.17415 9.78441 1.33523 9.8333 1.50003 9.83334ZM7.33336 15.6667V11.5H10.6667V15.6667H7.33336ZM9.00003 2.67834L14 7.67834V11.5L14.0009 15.6667H12.3334V11.5C12.3334 10.5808 11.5859 9.83334 10.6667 9.83334H7.33336C6.41419 9.83334 5.66669 10.5808 5.66669 11.5V15.6667H4.00003V7.67834L9.00003 2.67834Z"
                        fill="#2A547C"
                      ></path>
                    </svg>
                    Home
                  </span>
                </div>
              </a>
            </li>
            <li className="">
              <a
                className=""
                href="map"
                onClick={() => dispatch(setmodule("monitoring"))}
              >
                <div className="api-block">
                  <span className="icon-color">
                    <svg
                      width="18"
                      height="17"
                      viewBox="0 0 18 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.875 10.2499H17.75V8.3749C17.7492 7.71212 17.4855 7.07671 17.0169 6.60805C16.5482 6.13939 15.9128 5.87573 15.25 5.87491H14.625V4.93741C14.6243 4.35745 14.3937 3.80143 13.9836 3.39134C13.5735 2.98125 13.0175 2.75057 12.4375 2.74991C12.1125 2.75156 11.792 2.82655 11.5 2.96928V2.38116C11.5002 1.89014 11.3309 1.41411 11.0206 1.03354C10.7103 0.65298 10.2781 0.391224 9.79715 0.292526C9.31615 0.193828 8.81583 0.264242 8.38076 0.491867C7.94568 0.719491 7.60256 1.09036 7.40938 1.54178L6.08812 4.62491H4.625C3.7965 4.6259 3.00222 4.95546 2.41639 5.54129C1.83055 6.12713 1.50099 6.92141 1.5 7.7499V10.2499H4C4.8285 10.2489 5.62278 9.91935 6.20861 9.33352C6.79445 8.74768 7.12401 7.9534 7.125 7.12491V5.37803L8.55813 2.03428C8.63783 1.84736 8.77968 1.69374 8.95966 1.59941C9.13964 1.50508 9.34669 1.47585 9.54575 1.51666C9.74481 1.55747 9.92365 1.66582 10.052 1.82336C10.1803 1.9809 10.2503 2.17796 10.25 2.38116V12.7499H8.1325L5.875 14.8087L3.6175 12.7499H0.25V13.9999H3.1325L5.875 16.4999L8.6175 13.9999H17.75V12.7499H11.5V4.93741C11.5 4.68876 11.5988 4.45031 11.7746 4.27449C11.9504 4.09868 12.1889 3.99991 12.4375 3.99991C12.6861 3.99991 12.9246 4.09868 13.1004 4.27449C13.2762 4.45031 13.375 4.68876 13.375 4.93741V7.7499C13.3758 8.41269 13.6395 9.0481 14.1081 9.51676C14.5768 9.98542 15.2122 10.2491 15.875 10.2499ZM14.625 7.12491H15.25C15.5814 7.12524 15.8992 7.25704 16.1335 7.49139C16.3679 7.72574 16.4997 8.04349 16.5 8.3749V8.9999H15.875C15.5436 8.99957 15.2258 8.86777 14.9915 8.63342C14.7571 8.39907 14.6253 8.08132 14.625 7.7499V7.12491ZM5.875 7.12491C5.8745 7.62203 5.6768 8.09866 5.32528 8.45018C4.97375 8.80171 4.49713 8.99941 4 8.9999H2.75V7.7499C2.7505 7.25278 2.9482 6.77615 3.29972 6.42463C3.65125 6.0731 4.12787 5.8754 4.625 5.87491H5.875V7.12491Z"
                        fill="#2A547C"
                      ></path>
                    </svg>
                  </span>
                  Drought Monitoring
                </div>
              </a>
            </li>
            <li className="">
              <a
                className=""
                href="map"
                onClick={() => dispatch(setmodule("prediction"))}
              >
                <div className="api-block">
                  <span className="icon-color">
                    <svg
                      width="17"
                      height="17"
                      viewBox="0 0 18 18"
                      fill="#2A547C"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19.493 19.9993H0.506268C0.226729 19.9993 0 19.7724 0 19.4931C0 18.7212 0.300199 17.9974 0.845498 17.4556C1.38517 16.9122 2.10794 16.6121 2.87839 16.6121H8.81265C9.09219 16.6121 9.31892 16.839 9.31892 17.1183C9.31892 17.3979 9.092 17.6246 8.81265 17.6246L2.87839 17.6248C2.37972 17.6248 1.9121 17.819 1.56139 18.1717C1.3297 18.4018 1.16671 18.6813 1.08143 18.9868H18.9172C18.6961 18.2017 17.9747 17.6248 17.1212 17.6248H11.1873C10.9078 17.6248 10.6811 17.3979 10.6811 17.1185C10.6811 16.839 10.908 16.6122 11.1873 16.6122H17.1216C18.7089 16.6122 20 17.9047 20 19.4931C19.9998 19.7727 19.7731 19.9994 19.4936 19.9994L19.493 19.9993Z"
                        fill={color}
                      ></path>
                      <path
                        d="M14.1122 17.6251C13.9376 17.6251 13.7673 17.5345 13.6736 17.3722C12.9171 16.0649 11.5097 15.2528 10.0004 15.2528C8.49105 15.2528 7.08364 16.0649 6.32733 17.3722C6.1873 17.6143 5.87774 17.6973 5.63563 17.5567C5.39352 17.4167 5.31088 17.1071 5.45109 16.865C6.38755 15.2459 8.1308 14.2402 10.0002 14.2402C11.8697 14.2402 13.613 15.2461 14.5498 16.8652C14.6898 17.1073 14.6072 17.4169 14.3653 17.5569C14.2856 17.6032 14.1982 17.6251 14.1122 17.6251H14.1122Z"
                        fill={color}
                      ></path>
                      <path
                        d="M9.99846 15.2525C9.71892 15.2525 9.49219 15.0256 9.49219 14.7462V5.25285C9.49219 4.97331 9.7191 4.74658 9.99846 4.74658C10.278 4.74658 10.5047 4.9735 10.5047 5.25285V14.7462C10.5047 15.0258 10.278 15.2525 9.99846 15.2525Z"
                        fill={color}
                      ></path>
                      <path
                        d="M9.84277 12.8793H7.6257C6.03783 12.8793 4.74609 11.5874 4.74609 9.99968C4.74609 9.72014 4.97301 9.49341 5.25236 9.49341H7.46943C9.0573 9.49341 10.349 10.7853 10.349 12.373C10.3492 12.6526 10.1223 12.8793 9.84277 12.8793ZM5.8282 10.5059C6.04946 11.2902 6.77153 11.8668 7.6257 11.8668H9.26675C9.04548 11.0825 8.32342 10.5059 7.46943 10.5059H5.8282Z"
                        fill={color}
                      ></path>
                      <path
                        d="M10.0013 5.75927H7.628C4.73162 5.75927 2.375 3.4027 2.375 0.506268C2.375 0.226729 2.60191 0 2.88127 0H5.25461C8.15099 0 10.5076 2.35657 10.5076 5.253C10.5076 5.53254 10.2809 5.75927 10.0013 5.75927ZM3.41791 1.01258C3.66885 3.1131 5.46138 4.74673 7.62813 4.74673H9.46501C9.21425 2.64621 7.4219 1.01258 5.25479 1.01258H3.41791Z"
                        fill={color}
                      ></path>
                      <path
                        d="M9.99951 5.75942C9.90309 5.75942 9.80526 5.73205 9.71927 5.67431L6.15907 3.30097C5.92651 3.14575 5.86364 2.83159 6.01851 2.59905C6.17373 2.36666 6.48807 2.30344 6.72043 2.45848L10.2806 4.83182C10.5132 4.98704 10.5761 5.3012 10.4212 5.53374C10.3239 5.68049 10.1634 5.75942 9.9995 5.75942H9.99951Z"
                        fill={color}
                      ></path>
                      <path
                        d="M12.3718 5.75927H9.99846C9.71892 5.75927 9.49219 5.53235 9.49219 5.253C9.49219 2.35662 11.8488 0 14.7452 0H17.1185C17.3981 0 17.6248 0.226914 17.6248 0.506268C17.6248 3.40265 15.2682 5.75927 12.3718 5.75927ZM10.5349 4.74673H12.3718C14.5389 4.74673 16.3313 3.1131 16.582 1.01258H14.7451C12.578 1.01258 10.7857 2.64621 10.5349 4.74673Z"
                        fill={color}
                      ></path>
                      <path
                        d="M9.99899 5.7593C9.83547 5.7593 9.67478 5.68001 9.5773 5.53379C9.42208 5.30123 9.48512 4.9867 9.71787 4.83187L13.2781 2.45853C13.5106 2.30313 13.8252 2.36617 13.98 2.59909C14.1352 2.83166 14.0722 3.14618 13.8394 3.30102L10.2792 5.67436C10.1931 5.73193 10.0954 5.7593 9.99898 5.7593H9.99899Z"
                        fill={color}
                      ></path>
                      <path
                        d="M12.2155 12.8793H9.99846C9.71892 12.8793 9.49219 12.6524 9.49219 12.373C9.49219 10.7851 10.7839 9.49341 12.3718 9.49341H14.5889C14.8684 9.49341 15.0951 9.72032 15.0951 9.99968C15.0953 11.5876 13.8034 12.8793 12.2155 12.8793ZM10.5743 11.8668H12.2153C13.0695 11.8668 13.7916 11.2904 14.0128 10.5059H12.3718C11.5176 10.5059 10.7956 11.0825 10.5743 11.8668Z"
                        fill={color}
                      ></path>
                    </svg>
                    Drought Prediction
                  </span>
                </div>
              </a>
            </li>
            <li
              className=""
              onClick={async () => {
                modal.info(config);
              }}
            >
              <a className="">
                <div className="api-block">
                  <span className="icon-color">
                    <ExclamationCircleOutlined />
                  </span>
                  &nbsp;Disclaimer
                </div>
              </a>
            </li>
          </ul>
        </ul>
      </nav> */}
      {contextHolder}
    </>
  );
}
export default SubMenu;
