import React from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";

function NorthArrow() {
  const map = useMap();

  React.useEffect(() => {
    const northArrowControl = L.control({ position: "topright" });

    northArrowControl.onAdd = function () {
      const div = L.DomUtil.create(
        "div",
        "leaflet-bar leaflet-control leaflet-control-custom"
      );
      div.innerHTML = `
          <img
            src="https://creazilla-store.fra1.digitaloceanspaces.com/cliparts/3867221/north-arrow-clipart-sm.png"
            alt="North Arrow"
            style="width: 43px; height: 55px; transform: rotate(0deg); background-color: white; padding: 5px; border-radius: 5px;"
          />
        `;
      return div;
    };

    northArrowControl.addTo(map);

    return () => {
      northArrowControl.remove();
    };
  }, [map]);

  return null;
}

export default NorthArrow;
