import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Divider, List } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelected,
  setSelectedTwo,
  setSelectedWapor,
} from "../../slices/mapView";

const Indices = ({
  setSelectedMin,
  setSelectedMax,
  setloadlayer,
  setshowGraph,
  setshowOpacity,
  setshowprecipitation,
  setshowmonths,
  setapplyBtn,
  setgraphBtn,
  setlegend,
  openNotificationWithIcon,
  setmodalMessage,
  setmodalopen,
  compare,
  setLayerURL,
}) => {
  const dispatch = useDispatch();

  const { darkmode, selected, selectedTwo } = useSelector((state) => state);
  const handleIndice = (e) => {
    // if (selected && selectedTwo) return;
    const item = data.find((entry) => entry.title === e);
    if (item) {
      setSelectedMin(item.min);
      setSelectedMax(item.max);
    }

    if (!compare) {
      setloadlayer(false);
      setshowOpacity(false);
      setshowGraph(false);
      setshowprecipitation(false);
      setshowmonths(false);
      dispatch(setSelectedWapor(null));
      setLayerURL([]);
      // Handle single selection
      if (e === selected) {
        // Deselect `selected`
        setshowprecipitation(false);
        setshowmonths(false);
        setapplyBtn(true);
        setgraphBtn(false);
        setlegend(false);
        dispatch(setSelected(null));
      } else {
        // Select new item as `selected`
        setshowprecipitation(e === "extreme_rainfall" || e === "DrySpell");
        setshowmonths(e === "SPI_CHIRPS" || e === "SPI_ERA5L");
        if (e === "DrySpell" || e === "extreme_rainfall") {
          openNotificationWithIcon(
            "info",
            "Adjust precipitation threshold value",
            "",
            "bottomRight"
          );
        }
        setapplyBtn(false);
        setgraphBtn(e !== "DrySpell");
        setlegend(false);
        dispatch(setSelected(e));
      }
    } else {
      // Handle compare mode
      // if (e === selected) {
      // Deselect `selected`
      // dispatch(setSelected(null));
      // } else if (e === selectedTwo) {
      // Deselect `selectedTwo`
      //   dispatch(setSelectedTwo(null));
      // if (!selected) {
      //   dispatch(setSelected(e));
      // }
      // if (!selectedTwo) {
      setapplyBtn(false);
      dispatch(setSelectedTwo(e));
      // }
    }
  };

  const parentList = [
    "Agricultural Drought",
    "Meteorological Drought",
    "Impact",
  ];

  const data = [
    {
      title: "CDI",
      disabled: false,
      category: "",
      detail: "",
      min: 1,
      max: 5,
    },
    {
      title: "CWD",
      disabled: false,
      category: "Agricultural Drought",
      detail:
        '<div class="search-content-img discription-text"><p>Crop Water Deficit (CWD) is a critical measure used in agriculture to describe the difference between the water demand of a crop and the actual water available to it through precipitation or irrigation.</p><p>CWD=PET - ET</p><p><em><strong>Refrences:</strong> Singh, M., Singh, P., Singh, S. et al. A global meta-analysis of yield and water productivity responses of vegetables to deficit irrigation. Sci Rep 11, 22095 (2021). https://doi.org/10.1038/s41598-021-01433-w</em></p></div>',
      min: 0,
      max: 1,
    },
    {
      title: "DrySpell",
      disabled: false,
      category: "Meteorological Drought",
      detail:
        '<div class="search-content-img discription-text"><p>A dry spell is defined as the number of consecutive days with a daily precipitation amount below a certain threshold, such as 0.1, 1, 5, 10 mm, preceded and followed by at least one day with rainfall exceeding the threshold. The app uses rainfall product from CHIRPS to calculate the dry spell for specific time period ranging from few days to months.</p><p><em><strong>Reference:</strong> Suppiah, R. and Hennessy, K.J., 1998. Trends in total rainfall, heavy rain events and number of dry days in Australia, 1910-1990. International Journal of Climatology: A Journal of the Royal Meteorological Society, 18(10), pp.1141-1164.</em></p></div>',
      min: 0,
      max: 2,
    },
    {
      title: "MAI",
      disabled: false,
      category: "Agricultural Drought",
      detail:
        '<div className="search-content-img discription-text"><p>MAI stands for Moisture Adequacy Index.<br>Moisture Adequacy Index (MAI) is the ratio of actual evapotranspiration (AET) to the potential evapotranspiration (PET). Thus, MAI represents moisture effectivity which has impact on vegetation in relation to the climate. The range of MAI values varies between 0-1; value near to 0 reveals extreme stress while 1 expresses ample amount of soil moisture availability. &nbsp;&nbsp;<br>MAI = AET/PET</p><p><em><strong>Reference:</strong> Thornthwaite, C.W. and Mather, J.R., 1955. The water balance publications in Climatology, 8 (1). DIT, Laboratory of climatology, Centerton, NJ, USA.</em></p></div>',
      min: 0.1,
      max: 0.75,
    },
    {
      title: "NDVI",
      disabled: false,
      category: "Agricultural Drought",
      detail:
        "<p>NDVI stands for Normalize Difference Vegetation Index.<br>Although there are, several vegetation indices, one of the most widely used is the Normalized Difference Vegetation Index (NDVI). NDVI values range from +1.0 to -1.0. Areas of barren rock, sand, or snow usually show very low NDVI values (for example, 0.1 or less). Sparse vegetation such as shrubs and grasslands or senescing crops may result in moderate NDVI values (approximately 0.2 to 0.5).<br>NDVI = (NIR-RED)/(NIR+RED)<br>Where NIR = near infrared band, RED = red band<br><br><em><strong>Reference:</strong> Rouse Jr, J.W., Haas, R.H., Schell, J.A. and Deering, D.W., 1973. Monitoring the vernal advancement and retrogradation (green wave effect) of natural vegetation.</em></p>",
      min: -0.1,
      max: 0.8,
    },
    // {
    //   title: "NDWI",
    //   disabled: true,
    //   detail:
    //     '<div class="search-content-img discription-text"><p>NDWI stands for Normalize Difference Water Index.<br>The normalized difference water index can be utilized for evaluating vegetation liquid water contents or water inundated areas (Gao, 1996). NDWI is useful for evaluating reflectance from vegetation canopies that have similar scattering properties, but slightly different liquid water absorption due to canopy water content. As a result, NDWI is sensitive to changes in liquid water content of vegetation canopies and open water areas. The common range of NDWI for green vegetation is -0.1 to 0.4&nbsp;<br>There are different way of NDWI calculation as follows</p><p>NDWI = (GREEN-NIR)/(GREEN+NIR)<br>Where NIR = near infrared band, GREEN = green band</p><p><em><strong>Reference: </strong>Gao, B.C., 1996. NDWI - A normalized difference water index for remote sensing of vegetation liquid water from space. Remote Sensing of Environment, 58, 257–266.</em></p></div>',
    // },
    {
      title: "PCI",
      disabled: false,
      category: "Meteorological Drought",
      detail:
        '<div class="search-content-img discription-text"><p>The Precipitation Condition Index (PCI) is a tool used in drought monitoring to directly respond to precipitation anomalies, making it essential for assessing agricultural drought.</p><p>PCI = [Precipitation(i) - Precipitation(min)]/[Precipitation(max) - Precipitation(min)]</p><p><em><strong>Refrences:</strong> Zhao, Y.; Zhang, J.; Bai, Y.; Zhang, S.; Yang, S.; Henchiri, M.; Seka, A.M.; Nanzad, L. Drought Monitoring and Performance Evaluation Based on Machine Learning Fusion of Multi-Source Remote Sensing Drought Factors. Remote Sens. 2022, 14, 6398. https://doi.org/10.3390/rs14246398</em></p></div>',
      min: -1,
      max: 1,
    },
    {
      title: "SMCI",
      disabled: false,
      category: "Agricultural Drought",
      detail:
        '<div class="search-content-img discription-text"><p>The Soil Moisture Condition Index (SMCI) is a drought index that quantifies soil moisture to monitor agricultural drought conditions. It is derived from soil moisture and precipitation data, offering a direct measure of the soils wetness or dryness. This index is crucial for understanding and managing the impact of drought on agriculture by providing insights into the current state of soil moisture, which is vital for crop growth and productivity. </p><p>SMCI = [Soil_Moisture(i) - Soil_Moisture(min)]/[Soil_Moisture(max) - Soil_Moisture(min)]</p><p><em><strong>Refrences:</strong> Sánchez, N.; González-Zamora, Á.; Piles, M.; Martínez-Fernández, J. A New Soil Moisture Agricultural Drought Index (SMADI) Integrating MODIS and SMOS Products: A Case of Study over the Iberian Peninsula. Remote Sens. 2016, 8, 287. https://doi.org/10.3390/rs8040287</em></p></div>',
      min: 0,
      max: 1,
    },
    {
      title: "SPI_CHIRPS",
      disabled: false,
      category: "Meteorological Drought",
      detail:
        '<div class="search-content-img discription-text"><p>The Standardized Precipitation Index (SPI) is a metric used globally to identify and measure drought intensity by analyzing precipitation deficits over multiple time scales. Developed in 1993 by McKee, Doesken, and Kleist, the SPI can assess conditions ranging from wet to dry, providing a standardized approach to compare drought severity</p><p><em><strong>Refrences:</strong> Mckee, T. B. and Nolan J. Doesken. “The Standardized Precipitation Index – an overview.” (2016).</em></p></div>',
      min: -3,
      max: 3,
    },
    {
      title: "SPI_ERA5L",
      disabled: false,
      category: "Meteorological Drought",
      detail:
        '<div class="search-content-img discription-text"><p>The Standardized Precipitation Index (SPI) is a metric used globally to identify and measure drought intensity by analyzing precipitation deficits over multiple time scales. Developed in 1993 by McKee, Doesken, and Kleist, the SPI can assess conditions ranging from wet to dry, providing a standardized approach to compare drought severity</p><p><em><strong>Refrences:</strong> Mckee, T. B. and Nolan J. Doesken. “The Standardized Precipitation Index – an overview.” (2016).</em></p></div>',
      min: -3,
      max: 3,
    },
    {
      title: "TCI",
      disabled: false,
      category: "Agricultural Drought",
      detail:
        '<div class="search-content-img discription-text"><p>The Temperature Condition Index (TCI) is a remote sensing-based index used to assess vegetation thermal stress due to variations in temperature, including excessive heat or wetness. It utilizes thermal bands from satellite data to estimate the stress on vegetation by comparing current land surface temperature conditions against historical temperature data ranges.</p><p>TCI = [LST(max) - LST(i)]/[LST(max) - LST(min)]</p><p><em><strong>Refrences:</strong> Niranga Alahacoon & Mahesh Edirisinghe (2022) A comprehensive assessment of remote sensing and traditional based drought monitoring indices at global and regional scale, Geomatics, Natural Hazards and Risk, 13:1, 762-799, DOI: 10.1080/19475705.2022.2044394</em></p></div>',
      min: -0.5,
      max: 0.5,
    },
    {
      title: "VCI",
      disabled: false,
      category: "Agricultural Drought",
      detail:
        '<div className="search-content-img discription-text"><p>VCI stands for Vegetation Condition Index.<br>Pixel-based VCI calculation is more effective to identify the drought condition irrespective of the ecological region. The range of VCI values varies between 0-100 and the value 0 reveals extreme stress while 100 expresses healthy vegetation. NDVImin and NDVImax are the long-term minimum and maximum NDVI for given pixel and the NDVIi is the current NDVI for the same pixel.&nbsp;<br>VCI = NDVIi -NDVIminNDVImax-NDVImin*100</p><p><em><strong>Reference:</strong> Kogan F.N. 1995. Application of vegetation index and brightness temperature for drought detection. Advances in Space Research, 15(11): 91–100. DOI: 10.1016/0273-1177(95)00079-T.</em></p></div>',
      min: -0.5,
      max: 0.5,
    },
    {
      title: "VHI",
      disabled: false,
      category: "Impact",
      detail:
        '<div class="search-content-img discription-text"><p>The Vegetation Health Index (VHI) integrates the Vegetation Condition Index (VCI) and the Temperature Condition Index (TCI) to provide a comprehensive assessment of vegetation health, which is crucial for monitoring drought, assessing agricultural productivity, and managing food security. The VHI is instrumental in identifying areas with potential vegetation stress or favorable growth conditions, thus supporting decision-making in agriculture and environmental management.</p><p><em><strong>References:</strong> Zeng, J., Zhou, T., Qu, Y. et al. An improved global vegetation health index dataset in detecting vegetation drought. Sci Data 10, 338 (2023). https://doi.org/10.1038/s41597-023-02255-3</em></p></div>',
      min: -0.5,
      max: 0.5,
    },
  ];

  const ListItem = ({
    item,
    selected,
    selectedTwo,
    darkmode,
    handleIndice,
    setmodalMessage,
    setmodalopen,
  }) => {
    const isSelected = selected === item.title;
    const isSelectedTwo = selectedTwo === item.title;

    return (
      <List.Item
        style={{
          pointerEvents: item.disabled ? "none" : "",
          opacity: item.disabled ? 0.5 : 1,
          backgroundColor:
            isSelected || isSelectedTwo
              ? darkmode
                ? "#111a2c"
                : "#e6f4ff"
              : "",
          color: isSelected || isSelectedTwo ? "#1668dc" : null,
        }}
      >
        <div className="container">
          <div id="listItems">
            <div
              className="column first-column"
              onClick={() => handleIndice(item.title)}
            >
              {isSelected || isSelectedTwo ? (
                <FontAwesomeIcon icon={faEye} />
              ) : (
                <FontAwesomeIcon icon={faEyeSlash} />
              )}
            </div>
            <div
              className="column second-column"
              onClick={() => handleIndice(item.title)}
            >
              {item.title}
            </div>
            <div className="column third-column">
              {item.detail && (
                <FontAwesomeIcon
                  onClick={() => {
                    setmodalMessage(
                      <div dangerouslySetInnerHTML={{ __html: item.detail }} />
                    );
                    setmodalopen(true);
                  }}
                  icon={faInfoCircle}
                />
              )}
            </div>
          </div>
        </div>
      </List.Item>
    );
  };

  return (
    <div>
      {parentList.map((category) => (
        <div key={category}>
          <Divider
            style={{
              borderColor: "#2a547c",
            }}
          >
            {category}
          </Divider>
          <List
            itemLayout="horizontal"
            dataSource={data.filter((item) => item.category === category)}
            renderItem={(item) => (
              <ListItem
                item={item}
                selected={selected}
                selectedTwo={selectedTwo}
                darkmode={darkmode}
                handleIndice={handleIndice}
                setmodalMessage={setmodalMessage}
                setmodalopen={setmodalopen}
              />
            )}
          />
        </div>
      ))}
    </div>
  );
};

export default Indices;
