import Axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { Pane, TileLayer } from "react-leaflet";
import { useSelector } from "react-redux";

function JRCLayer(props) {
  const { loggedin } = useSelector((state) => state);
  const [data, setData] = useState([]);
  const { error } = props;

  useEffect(() => {
    async function fetchMyAPI() {
      await Axios.post("../python/getboundary", {
        params: {
          unit: "National",
        },
      }).then(function (response) {
        if (response.data.error && !response.data.data) {
          error(response.data.error);
        } else {
          const { mapid } = JSON.parse(response.data.data);
          setData(mapid);
        }
      });
    }

    if (loggedin) {
      fetchMyAPI();
    }
  }, []);

  if (data) {
    return (
      <Pane name="JRC" className="GridcellsPane">
        <TileLayer
          url={
            "https://earthengine.googleapis.com/v1alpha/" +
            data +
            "/tiles/{z}/{x}/{y}"
          }
        />
      </Pane>
    );
  } else {
    return "";
  }
}
export default JRCLayer;
